var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"left":"","data-test":"edit-icon"},domProps:{"textContent":_vm._s('edit')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-left",attrs:{"data-test":"edit-title"},domProps:{"textContent":_vm._s('Edit')}})],1),_c('v-dialog',{attrs:{"max-width":"520"},on:{"click:outside":_vm.close},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{attrs:{"data-test":"firewallRuleForm-card"}},[_c('v-card-title',{staticClass:"headline primary",attrs:{"data-test":"text-title"},domProps:{"textContent":_vm._s('Edit Firewall Rule')}}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.ruleStatus,"item-text":"text","item-value":"type","label":"Rule status","required":""},model:{value:(_vm.ruleFirewallLocal.status),callback:function ($$v) {_vm.$set(_vm.ruleFirewallLocal, "status", $$v)},expression:"ruleFirewallLocal.status"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Priority","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Rule priority","type":"number","error-messages":errors,"required":"","data-test":"priority-field"},model:{value:(_vm.ruleFirewallLocal.priority),callback:function ($$v) {_vm.$set(_vm.ruleFirewallLocal, "priority", $$v)},expression:"ruleFirewallLocal.priority"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Action","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.state,"item-text":"name","item-value":"id","label":"Rule policy","error-messages":errors,"required":"","data-test":"action-field"},model:{value:(_vm.ruleFirewallLocal.policy),callback:function ($$v) {_vm.$set(_vm.ruleFirewallLocal, "policy", $$v)},expression:"ruleFirewallLocal.policy"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-1 mb-1 px-3"},[_c('v-select',{attrs:{"label":"Source IP access restriction","items":_vm.sourceIPFieldChoices,"item-text":"filterText","item-value":"filterName","data-test":"source_ip-field"},model:{value:(_vm.choiceIP),callback:function ($$v) {_vm.choiceIP=$$v},expression:"choiceIP"}})],1),(_vm.choiceIP==='ipDetails')?_c('ValidationProvider',{attrs:{"name":"Source IP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Rule source IP","error-messages":errors,"required":""},model:{value:(_vm.ipField),callback:function ($$v) {_vm.ipField=$$v},expression:"ipField"}})]}}],null,true)}):_vm._e(),_c('v-row',{staticClass:"mt-1 mb-1 px-3"},[_c('v-select',{attrs:{"label":"Device username access restriction","items":_vm.usernameFieldChoices,"item-text":"filterText","item-value":"filterName","data-test":"username-field"},model:{value:(_vm.choiceUsername),callback:function ($$v) {_vm.choiceUsername=$$v},expression:"choiceUsername"}})],1),(_vm.choiceUsername==='username')?_c('ValidationProvider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username access restriction","placeholder":"Username used during the connection","error-messages":errors,"required":""},model:{value:(_vm.usernameField),callback:function ($$v) {_vm.usernameField=$$v},expression:"usernameField"}})]}}],null,true)}):_vm._e(),_c('v-row',{staticClass:"mt-1 mb-1 px-3"},[_c('v-select',{attrs:{"label":"Device access restriction","items":_vm.filterFieldChoices,"item-text":"filterText","item-value":"filterName","data-test":"filter-field"},model:{value:(_vm.choiceFilter),callback:function ($$v) {_vm.choiceFilter=$$v},expression:"choiceFilter"}})],1),(_vm.choiceFilter==='hostname')?_c('ValidationProvider',{attrs:{"name":"Hostname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Device hostname access restriction","placeholder":"Device hostname used during the connection","error-messages":errors,"data-test":"hostname-field","required":""},model:{value:(_vm.hostnameField),callback:function ($$v) {_vm.hostnameField=$$v},expression:"hostnameField"}})]}}],null,true)}):_vm._e(),(_vm.choiceFilter==='tags')?_c('ValidationProvider',{attrs:{"name":"Tags","vid":"tagsLength","rules":"tagRequired|tagsLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.tagNames,"data-test":"tags-selector","attach":"","chips":"","label":"Rule device tag restriction","error-messages":errors,"menu-props":{ top: true, maxHeight: 150, offsetY: true },"multiple":""},model:{value:(_vm.tagChoices),callback:function ($$v) {_vm.tagChoices=$$v},expression:"tagChoices"}})]}}],null,true)}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","data-test":"cancel-btn"},domProps:{"textContent":_vm._s('Cancel')},on:{"click":_vm.close}}),_c('v-btn',{attrs:{"text":"","data-test":"edit-btn"},domProps:{"textContent":_vm._s('Edit')},on:{"click":function($event){return passes(_vm.edit)}}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }